html, body {
  color: white;
  background-color: black;
  font-family: Helvetica;
  height: 100vh;
  width: 100vw;
  margin: 0;
}

#root {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App .buttons {
  flex: 1;
  padding-left: 3em;
  padding-right: 3em;
  padding-bottom: 3em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  -webkit-user-select: none;
  user-select: none;
}

.logo-wrapper {
  padding-top: 3em;
  padding-left: 3em;
  padding-right: 3em;
}

.logo {
  font-weight: bolder;
  font-size: xx-large;
  cursor: pointer;
}

div.block {
  background-color: white;
  width: fit-content;
}

div.logo-text {
  display: inline-block;
  color: black;
}

.btn {
  cursor: pointer;
  font-size: 5em;
  font-weight: 900;
  width: fit-content;
}

.page-content {
  flex: 100%;
  padding-top: 5rem;
  flex: 1;
  padding-left: 3em;
  padding-right: 3em;
}

.small-wrapper {
  padding-left: 3em;
  padding-bottom: 3em;
}

.mission-page p {
  width: 50%;
}

.animals > img {
  width: 96px;
  height: 96px;
}

@media only screen and (max-width: 500px) {
  .btn {
    font-size: 3em;
  }
  .animals > img {
    width: 72px;
    height: 72px;
  }
}

@media only screen and (max-width: 340px) {
  .btn {
    font-size: 2.5em;
  }

  .animals > img {
    width: 72px;
    height: 72px;
  }
}


@media only screen and (max-width: 800px) {
  .page-content {
    background: none !important;
  }

  .mission-page p {
    width: 100%;
  }

  .hide-mobile {
    display: none;
  }
}